import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
    fontSize: "14px",
  },
  btn: {
    color: "#FFF",
    textTransform: "initial",
  },
  btnList: {
    opacity: "0.8",
  },
  btnListItem: {
    fontSize: "15px",
  },
  link: {
    color: "#333",
  },
}));

export default function ProfileDrop(prop) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classes.btn}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {prop.children}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.btnList}
                  >
                    <MenuItem className={classes.btnListItem}>
                      <a href="https://qurvi.com.ar/" className={classes.link}>
                        Home
                      </a>
                    </MenuItem>
                    <MenuItem
                      className={classes.btnListItem}
                      component={Link}
                      to="./"
                    >
                      Dashboard
                    </MenuItem>
                    <MenuItem className={classes.btnListItem}>
                      <a
                        href="mailto:hola@qurvi.com.ar"
                        className={classes.link}
                      >
                        Contactanos
                      </a>
                    </MenuItem>
                    <MenuItem className={classes.btnListItem}>
                      <a
                        href="https://app.qurvi.com.ar/"
                        className={classes.link}
                      >
                        Logout
                      </a>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
