import { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Route, Routes, Link } from "react-router-dom";
import ItemListing from "./ItemListing";
import ItemCreate from "./ItemCreate";
import ItemDetail from "./ItemDetail";
import ItemBulk from "./ItemBulk";
import ItemEdit from "./ItemEdit";
import GlobalContext from "../../context/GlobalContext";
import TimerBar from "../../components/TimerBar";
import NotificationExpired from "../../components/NotificationExpired";
import Copyright from "../../components/Copyright";
import Avatar from "@material-ui/core/Avatar";
import ProfileDrop from "../../components/ProfileDrop";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    marginTop: "20px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    marginTop: "20px",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    marginTop: "20px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  avatar: {
    backgroundColor: "#198754",
    width: "30px",
    height: "30px",
    fontSize: "15px",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  //const [empdata, empdatachange] = useState([]);
  const { userData, setUserData } = useContext(GlobalContext);
  const { expiredUser, setExpiredUser } = useContext(GlobalContext);
  const userEmail = localStorage.getItem("id_user_email");
  const letterAvatar = userEmail.substring(0, 2).toUpperCase();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  console.log("user data", userData);
  return (
    <>
      {!expiredUser ? (
        <>
          <TimerBar />
          <div className={classes.root}>
            <>
              <CssBaseline />

              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <div className="content-topbar">
                    <div className="d-flex align-items-center">
                      <div className="mobile-hidde">
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          edge="start"
                          className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                          })}
                        >
                          <MenuIcon />
                        </IconButton>
                      </div>
                      <Typography variant="h6" noWrap>
                        Panel de control
                      </Typography>
                    </div>

                    <div className="right-topbar-side">
                      <ProfileDrop>
                        <div className="avatar-email ">{userEmail}</div>
                        <Avatar className={classes.avatar}>
                          {letterAvatar}
                        </Avatar>
                      </ProfileDrop>
                    </div>
                  </div>
                </Toolbar>
              </AppBar>
              <div className="side-drawer">
                <Drawer
                  variant="permanent"
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    }),
                  }}
                >
                  <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>
                  </div>
                  <Divider />
                  <Link to="./" className="btn menu-link">
                    <List>
                      <ListItem button>
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Lista de Productos"} />
                      </ListItem>
                    </List>
                  </Link>
                  <Divider />
                </Drawer>
              </div>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className="App content-main">
                  <Routes>
                    <Route path="/" element={<ItemListing />}></Route>
                    <Route
                      path="/items/create"
                      element={<ItemCreate />}
                    ></Route>
                    <Route
                      path="/items/bulkLoad"
                      element={<ItemBulk />}
                    ></Route>

                    <Route
                      path="/items/detail/:empid"
                      element={<ItemDetail />}
                    ></Route>
                    <Route
                      path="/items/edit/:empid"
                      element={<ItemEdit />}
                    ></Route>
                  </Routes>
                </div>
              </main>
            </>

            {/* <div className="not-found">
                  <img src="/page-not-found.png" />
                </div> */}
          </div>
        </>
      ) : (
        <NotificationExpired />
      )}

      <Copyright />
    </>
  );
}
