import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import iso from "../../assets/images/icon-isologo.svg";
import Copyright from "../../components/Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(23),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { userId, setUserId } = React.useContext(GlobalContext);
  const { userData, setUserData } = React.useContext(GlobalContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [user, setUser] = React.useState("");
  const [validation, valchange] = React.useState(false);
  const [error, setError] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  // React.useEffect(() => {
  //   callResponse();
  // }, []);

  const handlerSignIn = (e) => {
    e.preventDefault();

    const empdata = { email, password };
    console.log("data created", empdata);

    fetch("https://service-products.vercel.app/api/login/", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        console.log("login", res);
        if (res.status === 200) {
          return res.json();
        } else {
          alert("Acceso incorrecto");
          setError(true);
          return res.json();
        }
      })
      .then((data) => {
        // Access the user object and the ID from the response data
        setMessage(data.message);
        const { user } = data;

        if (user.id) {
          setMessage(message);
          setUserId(user.id);
          setUserData(user);

          localStorage.setItem("id_user_qr", user.id);
          localStorage.setItem("id_user_time", user.date);
          localStorage.setItem("id_user_email", user.email);
          console.log("User response:", user.date, user.id);
          setError(false);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Link href="https://qurvi.com.ar/">
        <img src={iso} className="iso-logo-image" />
      </Link>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h4">
          Ingresa a tu cuenta
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            onMouseDown={(e) => valchange(true)}
            error={email.length == 0 && validation && true}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            label="Nombre de Usuario / Correo Electronico"
            name="email"
            type="email"
            autoFocus
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            onMouseDown={(e) => valchange(true)}
            error={password.length == 0 && validation && true}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            name="password"
            label="Clave"
            type="password"
            id="password"
          />

          <Grid container>
            {error ? (
              <>
                <p className="error-login">{message}</p>
              </>
            ) : (
              <>
                {" "}
                <p className="success-login">{message}</p>
              </>
            )}
          </Grid>

          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handlerSignIn}
          >
            Acceder
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Olvide mi clave?
              </Link>
            </Grid> */}
            <Grid xs={12} item>
              No estas registrado?{" "}
              <Link href="/user/register" variant="body2">
                <span className="color-link">
                  <b>Crea tu cuenta</b>
                </span>
              </Link>
              <br />
              Cualquier consulta comunicate a{" "}
              <Link href="mailto:hola@qurvi.com.ar" variant="body2">
                <span className="color-link">hola@qurvi.com.ar</span>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <br />
      <br />
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>
  );
}
