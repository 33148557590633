import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import QRcode from "qrcode.react";
import GlobalContext from "../../context/GlobalContext";

const ItemListing = () => {
  const { userId, setUserId } = useContext(GlobalContext);

  const [empdata, empdatachange] = useState([]);
  const [emptyData, emptySetData] = useState(null);
  const [filterValue, setFilterValue] = useState();
  const [originData, setOriginData] = useState();
  const navigate = useNavigate();

  const LoadDetail = (id) => {
    navigate("./items/detail/" + id);
  };
  const LoadEdit = (id) => {
    navigate("./items/edit/" + id);
  };

  useEffect(() => {
    callResponse();
  }, []);

  const callResponse = () => {
    const storeId = localStorage.getItem("id_user_qr");
    const idUserProd = userId ? userId : storeId;
    fetch("https://service-products.vercel.app/api/products/user/" + idUserProd)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        empdatachange(resp);
        if (resp.length) {
          emptySetData(false);
        } else {
          emptySetData(true);
        }

        setOriginData(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log("id", userId);
  };

  const Removefunction = (id) => {
    if (window.confirm("Estas seguro que quieres remover el producto?")) {
      fetch("https://service-products.vercel.app/api/products/" + id, {
        method: "DELETE",
      })
        .then((res) => {
          alert("El producto fue removido exitosamente.");
          //window.location.reload();
          callResponse();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  console.log("empty data", emptyData);
  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "myqr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    if (filterValue !== "") {
      const filtered = empdata.filter((user) => user.name.includes(value));
      empdatachange(filtered);
    } else {
      empdatachange(originData);
    }
  };
  const handleFilterBlur = () => {
    if (filterValue === "") {
      empdatachange(originData);
      setFilterValue("");
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between  mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <Link to="./items/create" className="btn btn-success mr-2">
                  Agrega un producto (+)
                </Link>
                <button className="btn btn-success" disabled="true">
                  Carga masiva de productos
                </button>
                {/* <Link to="./items/bulkLoad" className="btn btn-success">
                  Carga masiva de productos
                </Link> */}
              </div>
            </div>

            <div className="mobile-hidde">
              <div className="input-group mb-3">
                <input
                  type="text"
                  onChange={handleFilter}
                  onBlur={handleFilterBlur}
                  value={filterValue}
                  className="form-control"
                  placeholder="Buscar producto"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text search-icon"
                    id="basic-addon2"
                  >
                    <img src="/search-icon.svg" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <>
            <table className="table table-bordered">
              <thead className="bg-dark text-white">
                <tr>
                  <td>Código-id</td>
                  <td>Stock</td>
                  <td>Nombre</td>
                  <td>Precio</td>
                  <td>Acciones</td>
                </tr>
              </thead>
              <tbody>
                {empdata &&
                  empdata.map((item) => (
                    <tr key={item.qrCode}>
                      <td className="d-none">
                        <div className="qr-container">
                          <QRcode
                            id={item._id}
                            value={
                              "https://app.qurvi.com.ar/qrview/" + item._id
                            }
                            size={400}
                            includeMargin={true}
                          />
                        </div>
                      </td>
                      <td>{item._id}</td>
                      <td>{item.stock ? item.stock : "-"}</td>
                      <td className="product-name">{item.name}</td>
                      <td>
                        {item.price.toLocaleString("es-AR", {
                          style: "currency",
                          currency: "ARS",
                        })}
                      </td>
                      <td className="action-column">
                        <a
                          className="btn btn-primary"
                          onClick={() => {
                            downloadQR(item._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-cloud-download-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0m-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708z"
                            />
                          </svg>
                        </a>
                        <a
                          onClick={() => {
                            Removefunction(item._id);
                          }}
                          className="btn btn-danger"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </a>
                        <a
                          onClick={() => {
                            LoadEdit(item._id);
                          }}
                          className="btn btn-success"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                          </svg>
                        </a>

                        <a
                          onClick={() => {
                            LoadDetail(item._id);
                          }}
                          className="btn btn-primary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  ))}

                {!empdata.length && !emptyData && (
                  <p class="charging-text">Cargando...</p>
                )}
              </tbody>
            </table>
            {emptyData && (
              <div className="alert-empry-mesaje">
                No hay productos cargados{" "}
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default ItemListing;
