import { useEffect, useState, useContext } from "react";

import GlobalContext from "../../context/GlobalContext";
const UserDashboard = () => {
  const { userId, setUserId } = useContext(GlobalContext);

  const [empdata, empdatachange] = useState([]);
  const [emptyData, emptySetData] = useState(null);
  const [filterValue, setFilterValue] = useState();
  const [originData, setOriginData] = useState();

  useEffect(() => {
    callResponse();
  }, []);

  const callResponse = () => {
    fetch("https://service-products.vercel.app/api/users/")
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        empdatachange(resp);
        if (resp.length) {
          emptySetData(false);
        } else {
          emptySetData(true);
        }

        setOriginData(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
    console.log("id", userId);
  };

  const Removefunction = (id) => {
    if (window.confirm("Estas seguro que quieres remover el producto?")) {
      fetch("https://service-products.vercel.app/api/users/" + id, {
        method: "DELETE",
      })
        .then((res) => {
          alert("El usuario fue removido exitosamente.");
          //window.location.reload();
          callResponse();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  console.log("empty data", emptyData);

  const handleFilter = (event) => {
    const value = event.target.value;
    setFilterValue(value);

    if (filterValue !== "") {
      const filtered = empdata.filter((user) => user.email.includes(value));
      empdatachange(filtered);
    } else {
      empdatachange(originData);
    }
  };
  const handleFilterBlur = () => {
    if (filterValue === "") {
      empdatachange(originData);
      setFilterValue("");
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between  mb-2">
            <div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  onChange={handleFilter}
                  onBlur={handleFilterBlur}
                  value={filterValue}
                  className="form-control"
                  placeholder="Buscar usuario"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text search-icon"
                    id="basic-addon2"
                  >
                    <img src="/search-icon.svg" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <>
            <table className="table table-bordered">
              <thead className="bg-dark text-white">
                <tr>
                  <td>Id</td>
                  <td>Email</td>
                  <td>Password</td>
                  <td>Date</td>
                  <td>Acciones</td>
                </tr>
              </thead>
              <tbody>
                {empdata &&
                  empdata.map((item) => (
                    <tr key={item._id}>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      <td>{item.password}</td>
                      <td>{item.date}</td>

                      <td>
                        <a
                          onClick={() => {
                            Removefunction(item._id);
                          }}
                          className="btn btn-danger"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  ))}
                {!empdata && <p>loading...</p>}
              </tbody>
            </table>
            {emptyData && (
              <div className="alert-empry-mesaje">
                No hay usuarios cargados{" "}
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
