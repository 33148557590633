import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate } from "react-router-dom";
import iso from "../../assets/images/icon-isologo.svg";
import Copyright from "../../components/Copyright";
import GlobalContext from "../../context/GlobalContext";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(23),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default function Register() {
  const { userId, setUserId } = React.useContext(GlobalContext);
  const { userData, setUserData } = React.useContext(GlobalContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [message, setMessage] = React.useState("");
  const [validation, valchange] = React.useState(false);
  const [error, setError] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const handlerRegister = (e) => {
    e.preventDefault();

    const empdata = { email, password, date };
    console.log("data created", empdata);

    fetch("https://service-products.vercel.app/api/register/", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        console.log("register res", res);
        if (res.status !== 400) {
          alert("Registro correcto");
          return res.json();
        } else {
          alert("Registro incorrecto");
          setError(true);
          return res.json();
        }
      })
      .then((data) => {
        // Access the user object and the ID from the response data

        console.log("incorrect register", data);
        setMessage(data.message);

        if (data.user._id) {
          setUserId(data.user._id);
          setUserData(data.user);
          console.log("User response:", data);
          localStorage.setItem("id_user_qr", data.user._id);
          localStorage.setItem("id_user_time", data.user.date);
          localStorage.setItem("id_user_email", data.user.email);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // const handlerRegister = (e) => {
  //   e.preventDefault();

  //   const empdata = { email, password, date };
  //   console.log("data created", empdata);

  //   fetch("http://localhost:9000/api/register/", {
  //     method: "POST",
  //     headers: { "content-type": "application/json" },
  //     body: JSON.stringify(empdata),
  //   })
  //     .then((res) => {
  //       //console.log("login", res.status);
  //       if (res.status !== 400) {
  //         alert("Registro correcto");
  //         return res.json();
  //       } else {
  //         alert("Registro incorrecto");

  //         setError(true);
  //       }
  //     })
  //     .then((data) => {
  //       if (data.user) {
  //         setMessage(data.message);
  //         setUserId(data.user._id);
  //         setUserData(data.user);

  //         console.log("dataResp:", userData);
  //         localStorage.setItem("id_user_qr", data.user._id);
  //         localStorage.setItem("id_user_time", data.user.date);
  //         localStorage.setItem("id_user_email", data.user.email);
  //         navigate("/dashboard");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  return (
    <Container component="main" maxWidth="xs">
      <Link href="https://qurvi.com.ar/">
        <img src={iso} className="iso-logo-image" />
      </Link>
      <div className={classes.paper}>
        <div className="rocket-icon">🚀</div>
        <Typography component="h1" variant="h4">
          Creá tu cuenta
        </Typography>

        <Typography component="h1" variant="body1">
          Completa los campos para continuar
        </Typography>

        <form className={classes.form}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            onMouseDown={(e) => valchange(true)}
            error={email.length == 0 && validation && true}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            label="Ingresa un Correo Electronico"
            name="email"
            type="email"
            autoFocus
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            onMouseDown={(e) => valchange(true)}
            error={password.length == 0 && validation && true}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            name="password"
            label="Crea tu Clave"
            type="password"
            id="password"
          />

          <Grid container>
            {error && (
              <>
                <p className="error-login">{message}.</p>
              </>
            )}
          </Grid>
          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            className={classes.submit}
            onClick={handlerRegister}
          >
            Continuar
          </Button>
          <Grid xs={12} item>
            Ya estas registrado?{" "}
            <Link href="/" variant="body2">
              <span className="color-link">
                <b>Entra a la cuenta</b>
              </span>
            </Link>
            <br />
            Cualquier consulta comunicate a{" "}
            <Link href="mailto:hola@qurvi.com.ar" variant="body2">
              <span className="color-link">hola@qurvi.com.ar</span>
            </Link>
          </Grid>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Olvide mi clave?
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <br />
      <br />
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>
  );
}
