import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import QRcode from "qrcode.react";
const ItemDetail = () => {
  const { empid } = useParams();

  const [empdata, empdatachange] = useState({});

  useEffect(() => {
    fetch("https://service-products.vercel.app/api/products/" + empid)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        empdatachange(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const downloadQR = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "myqr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      {/* <div className="row">
                <div className="offset-lg-3 col-lg-6"> */}

      <div className="container">
        <div
          className="card row"
          style={{ textAlign: "left", padding: "20px" }}
        >
          <div className="card-body"></div>

          {empdata && (
            <div>
              <h5>
                Producto : <b>{empdata.name}</b> ({empdata.qrCode})
              </h5>
              <p>
                Stock : <b>{empdata.stock ? "Hay stock" : "-"}</b>
              </p>

              <h1>
                <b>
                  {empdata.price &&
                    empdata.price.toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                </b>
              </h1>
              <hr />
              <QRcode
                id={empdata._id}
                value={"https://app.qurvi.com.ar/qrview/" + empdata._id}
                size={250}
                includeMargin={true}
              />
              <div>
                <Link className="btn btn-danger" to="/dashboard">
                  Volver a la lista
                </Link>
                <a
                  className="btn btn-primary"
                  onClick={() => {
                    downloadQR(empdata._id);
                  }}
                >
                  Descargar
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* </div>
            </div> */}
    </div>
  );
};

export default ItemDetail;
