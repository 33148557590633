import React from "react";

function NotificationExpired() {
  return (
    <div className="notification-expired-content">
      <div>
        <h2 className="spacing">🕒 ¡Tu prueba de 7 días ha terminado!</h2>
        <h3 className="spacing">
          ¿Estas interesado en nuestros planes 🚀 o{" "}
          <b>te interesa extender tu prueba? Contáctanos en</b>{" "}
          <a href="mailto:hola@qurvi.com.ar">hola@qurvi.com.ar</a> para obtener
          más información.
        </h3>
        <p>¡Gracias por confiar en QURVI! 😉</p>
      </div>
    </div>
  );
}

export default NotificationExpired;
