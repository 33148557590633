import React, { useEffect, useState } from "react";
import GlobalContext from "../context/GlobalContext";
function TimerBar() {
  const { userData, setUserData } = React.useContext(GlobalContext);
  const { expiredUser, setExpiredUser } = React.useContext(GlobalContext);
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    // Set the target date to 7 days from now
    const dateUser = userData
      ? userData.date
      : localStorage.getItem("id_user_time");
    const targetDate = new Date(dateUser);
    targetDate.setDate(targetDate.getDate() + 7);

    const interval = setInterval(() => {
      const currentDate = new Date();
      const difference = targetDate - currentDate;

      // Check if the target date has passed
      if (difference <= 0) {
        setCountdown("Countdown Over");
        alert(
          "Tus 7 días de prueba ya se completaron, contactanos para que te contemos nuestra propuesta!!"
        );
        setExpiredUser(true);
        clearInterval(interval);
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="notification-bar-timer">
      <p>
        Te restan <b>{countdown}</b> de prueba | agranda tu plan, hablanos a
        hola@qurvi.com.ar 🚀
      </p>
      {/* You can render other components here */}
    </div>
  );
}

export default TimerBar;
