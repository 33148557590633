import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
const ItemCreate = () => {
  const { userId, setUserId } = useContext(GlobalContext);
  const [name, namechange] = useState("");
  const [price, pricechange] = useState("");
  const [stock, stockchange] = useState("");
  const [validation, valchange] = useState(false);

  const navigate = useNavigate();
  function generateRandomAlphanumeric(length) {
    const alphanumericCharacters = "0123456789abcdefghijklmnopqrstuvwxyz";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(
        Math.random() * alphanumericCharacters.length
      );
      result += alphanumericCharacters.charAt(randomIndex);
    }

    return result;
  }
  const handlesubmit = (e) => {
    e.preventDefault();
    const qrCode = generateRandomAlphanumeric(5);
    console.log("qrCode", qrCode);
    const user_id = userId;
    const empdata = { qrCode, name, price, stock, user_id };
    console.log("data created", empdata);

    fetch("https://service-products.vercel.app/api/products/", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        alert("El producto fue guardado exitosamente.");
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-8">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div
                className="card-title"
                style={{ margin: "15px 15px 0 15px" }}
              >
                <h2>Crear tu código [QR]</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Nombre del producto*</label>
                      <input
                        required
                        value={name}
                        onMouseDown={(e) => valchange(true)}
                        onChange={(e) => namechange(e.target.value)}
                        className="form-control"
                      ></input>
                      {name.length == 0 && validation && (
                        <span className="text-danger">
                          Agregar el nombre del producto
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Precio*</label>
                      <input
                        required
                        value={price}
                        onMouseDown={(e) => valchange(true)}
                        onChange={(e) => pricechange(e.target.value)}
                        className="form-control"
                      ></input>
                      {price.length == 0 && validation ? (
                        <span className="text-danger">
                          Agregar el precio del producto
                        </span>
                      ) : (
                        <span style={{ opacity: "0.5" }}>
                          Agregar el precio del producto sin (.) ni (,)
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Stock</label>
                      <input
                        value={stock}
                        onChange={(e) => stockchange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <div className="form-group">
                      <Link to="/dashboard" className="btn btn-danger">
                        Volver
                      </Link>
                      <button className="btn btn-success" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ItemCreate;
