import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import templateExcel from "../../assets/file/file.xlsx";
import * as XLSX from "xlsx";
const ItemCreate = () => {
  const { userId, setUserId } = useContext(GlobalContext);
  // onchange states
  const [excelValidate, setExcelValidate] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);

  // onchange event
  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  // submit event
  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data.slice(0, 15));
    }
  };

  const navigate = useNavigate();

  const handlesubmit = (e) => {
    e.preventDefault();

    const user_id = userId;
    console.log("carga excel", excelData, user_id);
    const empdata = { excelData };

    fetch("https://service-products.vercel.app/api/products/", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        alert("El producto fue guardado exitosamente.");
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handlerDownload = () => {
    setExcelValidate(true);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" style={{ textAlign: "left" }}>
            <div className="card-title" style={{ margin: "25px 15px 0 15px" }}>
              {!excelValidate ? (
                <>
                  <h2>Descarga la plantilla para cargar tus productos</h2>
                  <a
                    href={templateExcel}
                    download="Plantilla_para_carga_de_productos"
                    className="btn btn-success btn-md mt-4"
                    onClick={handlerDownload}
                  >
                    Descargar plantilla
                  </a>
                </>
              ) : (
                <>
                  <h2>Subi tu plantilla para cargar tus productos</h2>
                </>
              )}
            </div>

            <div className="card-body">
              <div className="row">
                {excelValidate && (
                  <>
                    <div className="col-lg-12 mt-2">
                      <form
                        className="form-group custom-form"
                        onSubmit={handleFileSubmit}
                      >
                        <div className="row">
                          <div className="col-11 col-sm-10 col-lg-5">
                            <input
                              type="file"
                              className="form-control"
                              required
                              onChange={handleFile}
                            />
                          </div>
                          <div className="col-1 col-sm-1 col-lg-1">
                            <button
                              type="submit"
                              className="btn btn-success btn-md upload-btn"
                            >
                              Cargar
                            </button>
                            {typeError && (
                              <div className="alert alert-danger" role="alert">
                                {typeError}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                      {/* view data */}
                      <div className="viewer mt-4">
                        {excelData ? (
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  {Object.keys(excelData[0]).map((key) => (
                                    <th key={key}>{key}</th>
                                  ))}
                                </tr>
                              </thead>

                              <tbody>
                                {excelData.map((individualExcelData, index) => (
                                  <tr key={index}>
                                    {Object.keys(individualExcelData).map(
                                      (key) => (
                                        <td key={key}>
                                          {individualExcelData[key]}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div>
                            No hay archivo subido,{" "}
                            <b>
                              recuerda cargar tus productos en la plantilla
                              descargada.
                            </b>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-12 mt-4">
                  <div className="form-group">
                    <Link to="/dashboard" className="btn btn-danger">
                      Volver
                    </Link>
                    <button
                      className="btn btn-success"
                      disabled={!excelData}
                      onClick={handlesubmit}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCreate;
