import "./assets/app.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Register from "./pages/register";
import ViewPage from "./pages/viewPage";
import UserDashboard from "./pages/userDashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/user/register" element={<Register />}></Route>
          <Route path="/dashboard/*" element={<Dashboard />}></Route>
          <Route path="/qrview/:empid" element={<ViewPage />}></Route>
          <Route path="/users/dashboard" element={<UserDashboard />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
