import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
export default function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="copy-right"
    >
      {"Copyright© "}
      <Link color="inherit" href="https://qurvi.com.ar/">
        qurvi.com.ar
      </Link>{" "}
      {new Date().getFullYear()} | <b>hola@qurvi.com.ar</b> -{" "}
      <span className="version-panel">Beta V-0.1</span>
    </Typography>
  );
}
