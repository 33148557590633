import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const ItemEdit = () => {
  const { empid } = useParams();
  const [name, namechange] = useState("");
  const [price, pricechange] = useState("");
  const [stock, stockchange] = useState("");
  const [validation, valchange] = useState(false);
  //const [empdata, empdatachange] = useState({});

  useEffect(() => {
    fetch("https://service-products.vercel.app/api/products/" + empid)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        namechange(resp.name);
        pricechange(resp.price);
        stockchange(resp.stock);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const navigate = useNavigate();

  const handlesubmit = (e) => {
    e.preventDefault();
    const empdata = { name, price, stock };
    console.log("edit", empdata);
    fetch("https://service-products.vercel.app/api/products/" + empid, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(empdata),
    })
      .then((res) => {
        alert("El producto fue editado exitosamente.");
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <div>
      <div className="row">
        <div className="col-lg-8">
          <form className="container" onSubmit={handlesubmit}>
            <div className="card" style={{ textAlign: "left" }}>
              <div
                className="card-title"
                style={{ margin: "15px 15px 0 15px" }}
              >
                <h2>Editar producto</h2>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        required
                        value={name}
                        onMouseDown={(e) => valchange(true)}
                        onChange={(e) => namechange(e.target.value)}
                        className="form-control"
                      ></input>
                      {name.length == 0 && validation && (
                        <span className="text-danger">Enter the name</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Precio</label>
                      <input
                        value={price}
                        onChange={(e) => pricechange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Stock</label>
                      <input
                        value={stock}
                        onChange={(e) => stockchange(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="form-group">
                      <Link to="/dashboard/" className="btn btn-danger">
                        Volver
                      </Link>
                      <button className="btn btn-success" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ItemEdit;
