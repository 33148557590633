import React, { useState } from "react";
import GlobalContext from "./GlobalContext";

const Provider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [expiredUser, setExpiredUser] = useState(false);
  //   const updateData = (newData) => {
  //     setUserId(newData);
  //   };

  return (
    <GlobalContext.Provider
      value={{
        userId,
        setUserId,
        userData,
        setUserData,
        expiredUser,
        setExpiredUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default Provider;
