import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./viewPage.css";

const ViewPage = () => {
  const { empid } = useParams();

  const [empdata, empdatachange] = useState({});

  useEffect(() => {
    fetch("https://service-products.vercel.app/api/products/" + empid)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        empdatachange(resp);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div>
      {/* <div className="row">
                <div className="offset-lg-3 col-lg-6"> */}
      <a className="qrLink" href="https://www.qurvi.com.ar" target="_BLANK">
        qurvi.com.ar
      </a>
      <div className="content-qrview">
        <div
          className="card row"
          style={{ textAlign: "left", padding: "20px" }}
        >
          <div className="card-body"></div>

          {empdata.name ? (
            <>
              <div>
                <h5>
                  <b>{empdata.name}</b> <br />
                  <span>({empdata._id})</span>
                </h5>
                <p>
                  Stock : <b>{empdata.stock ? "Hay stock" : "-"}</b>
                </p>

                <h1>
                  <b>
                    {empdata.price.toLocaleString("es-AR", {
                      style: "currency",
                      currency: "ARS",
                    })}
                  </b>{" "}
                  -
                </h1>
                <hr />
              </div>
            </>
          ) : (
            <p className="p-leading">Cargando...</p>
          )}
        </div>
      </div>
      {/* </div>
            </div> */}
    </div>
  );
};

export default ViewPage;
